<template>
  <div>
    <c-card title="승인 상세">
      <template slot="card-button">
        <q-btn-group outline >
          <c-btn v-if="editable&&!param.disabled" label="승인" icon="approval" />
          <c-btn v-if="editable&&!param.disabled" label="LBLSAVE" icon="save" />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-radio 
            :disabled="param.disabled"
            :editable="editable"
            :comboItems="approvItems"
            label="승인여부"
            name="col1" 
            v-model="mocApproval.col1">
          </c-radio>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :disabled="true"
            :editable="editable"
            label="승인일"
            name="col2"
            v-model="mocApproval.col2"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-textarea
            :disabled="param.disabled"
            :editable="editable"
            label="승인근거"
            name="col3" 
            v-model="mocApproval.col3">
          </c-textarea>
        </div>
        <div class="col-12">
          <c-upload 
            :editable="editable&&!param.disabled">
          </c-upload>
        </div>
      </template>
    </c-card>
  </div>
</template>

<script>
export default {
  name: 'moc-approval',
  props: {
    param: {
      type: Object,
      default: () => ({
        disabled: false,
      }),
    },
  },
  data() {
    return {
      mocApproval: {
        col1: '1',
        col2: '2021-06-25',
        col3: 'xxxxx로 인한 승인',
      },
      approvItems: [
        { code: '1', codeName: '승인' },
        { code: '2', codeName: '미승인' },
      ],
      editable: true,
      searchUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      // code setting
      // list setting
    },
  }
};
</script>
